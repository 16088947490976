


































































import { Empresa } from '@/core/models/geral';
import { PageBase } from '@/core/models/shared';
import { ReceitaService } from '@/core/services/financeiro';
import { EmpresaService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class DetalharRegistroOperacional extends PageBase{


    service = new ReceitaService();
    itens: any[] = [];
    empresaService = new EmpresaService();
    empresa:Empresa = new Empresa();
    overlay: boolean = false;
    filtro : any = {
        numeroRo: null,
        clienteId: null,
        embarcacaoId: null,
        tipoReceitaId: null,
        dataInicio: null,
        dataFim: null,
        tipo:null,
        dataSaidaRoa:null
    }

    mounted() {
        this.filtro.numeroRo = this.$route.query.numeroRo;
        this.filtro.clienteId = this.$route.query.clienteId;
        this.filtro.embarcacaoId = this.$route.query.embarcacaoId;
        this.filtro.tipoReceitaId = this.$route.query.tipoReceitaId;
        this.filtro.dataInicio = this.$route.query.dataInicio;
        this.filtro.dataFim = this.$route.query.dataFim;
        this.filtro.tipo = this.$route.query.tipo;
        this.filtro.dataSaidaRoa = this.$route.query.dataSaidaRoa;
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;
        
        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res=>{
                this.empresa = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )

        this.service.DetalharEquipamento(this.filtro.numeroRo, this.filtro.clienteId, this.filtro.embarcacaoId, this.filtro.tipoReceitaId, this.filtro.dataInicio, this.filtro.dataFim,this.filtro.dataSaidaRoa).then(
            res => {
                this.itens = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }
}
